<template>
  <div class="login-container">
    <div class="centercontain">
      <div class="left">
        <img class="backimage" :src="icon2" />
        <div class="logocintain">
          <img class="logo" :src="icon6" />
        </div>
      </div>

      <div class="right">
        <div class="logoitem">
          <img class="logo" :src="icon1" v-if="schoolid == 1" style="width: 300px;" />
          <img class="logo" :src="icon1_1" v-else />
        </div>
        <div class="logoitem logoitem2">
          <img class="logo" :src="icon7" />
        </div>
        <el-form ref="loginForm" :rules="loginRules" :model="loginForm" class="elform">
          <el-form-item label class="elitem" prop="username">
            <div class="inputcontain">
              <img class="user" :src="icon5" />
              <el-input
                class="elinput"
                v-model.trim="loginForm.username"
                placeholder="请输入用户名"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label class="elitem elitem2" prop="password">
            <div class="inputcontain">
              <img class="user" :src="icon3" />
              <el-input
                class="elinput"
                v-model.trim="loginForm.password"
                placeholder="请输入密码"
                show-password
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </el-form>

        <div class="logoitem passtip">
          <div class="contain">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
            <div class="el-checkbox__label">
              <div class="logoitem passtip">
                <div class="contain_reg" @click="register">
                  <span>注册账号</span>
                  <!-- <div>忘记密码?</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="logoitem logbt">
          <el-button class="bt" v-if="loading">
            <span class="el-icon-loading loadingIcon"></span>
            <span class="loadingText">正在登录中</span>
          </el-button>
          <el-button class="bt" v-else @click="handleLogin">登录</el-button>
          <!-- <el-button @click="authentication">统一身份认证</el-button> -->
        </div>
        <!-- <div class="logoitem logbt">
          <el-button class="bt" @click="authentication">统一身份认证</el-button>
        </div>-->
      </div>
    </div>

    <el-dialog
      class="my_eldialog"
      title
      :visible.sync="dialogVisible"
      width="34%"
      :show-close="false"
      :close-on-click-modal="false"
      top="25vh"
    >
      <div class="dialog_con">
        <div class="title_t">请选择身份</div>
        <div class="icon_con">
          <div class="item_con" v-if="is_inarr('1')" @click="btclick(1)">
            <div class="el_icon">
              <img class="img_icon" :src="icon00" />
              <img v-if="btid == 1" class="img_icon" :src="icon04" />
            </div>
            <div class="text_t">学生</div>
          </div>
          <div class="item_con" v-if="is_inarr('2')" @click="btclick(2)">
            <div class="el_icon">
              <img class="img_icon" :src="icon01" />
              <img v-if="btid == 2" class="img_icon" :src="icon04" />
            </div>
            <div class="text_t">老师</div>
          </div>
          <div class="item_con" v-if="is_inarr('3')" @click="btclick(3)">
            <div class="el_icon">
              <img class="img_icon" :src="icon02" />
              <img v-if="btid == 3" class="img_icon" :src="icon04" />
            </div>
            <div class="text_t">管理员</div>
          </div>
          <div class="item_con" v-if="is_inarr('4')" @click="btclick(4)">
            <div class="el_icon">
              <img class="img_icon" :src="icon03" />
              <img v-if="btid == 4" class="img_icon" :src="icon04" />
            </div>
            <div class="text_t">校外人员</div>
          </div>
        </div>
        <div class="bt_con">
          <el-button type="primary" class="bt" @click="bt_enter">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getuserinfo } from "@/api/user"
import { validUsername } from "@/utils/validate"
import { getschoolinfo } from "@/api/school.js"
import {
  getIdentifierToken,
  setIdentifierToken,
  setWebNameToken,
  setWebAvaterToken,
} from "@/utils/auth"

const request_base_path = require("../../../src/utils/base_url")

export default {
  name: "Login",
  components: {},
  data () {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("请输入正确的登录密码"))
      } else {
        callback()
      }
    }
    return {
      btid: -1,
      dialogVisible: false,
      identifiers: "",
      icon1: require("@/assets/登录/ujoylogo.png"),
      icon1_1: require("@/assets/shifan02.png"),
      icon2: require("@/assets/登录/ujoywzbg.png"),
      icon3: require("@/assets/登录/ujoypwd.png"),
      icon4: require("@/assets/登录/bg1.jpg"),
      icon5: require("@/assets/登录/ujoyuser.png"),
      icon6: require("@/assets/登录/title.png"),
      icon7: require("@/assets/登录/ujoyline.png"),
      icon00: require("@/assets/学生.png"),
      icon01: require("@/assets/老师.png"),
      icon02: require("@/assets/管理员.png"),
      icon03: require("@/assets/校外人员.png"),
      icon04: require("@/assets/遮罩.png"),
      checked: false,
      // loading:'true',
      loginForm: {
        username: "", // 手机号格式
        password: "",
      },
      loginRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      schoolid: "",
      schoolinfo: {},
    }
  },

  created () {
    this.schoolid = localStorage.getItem("schoolid") * 1 || 1
    let info = localStorage.getItem("schoolinfo")
    if (info) {
      this.schoolinfo = JSON.parse(info)
    } else {
      getschoolinfo({}).then((res) => {
        if (res && res.code == 0) {
          this.schoolinfo = res.data
        }
      })
    }
  },
  mounted () {
    this.getCookie()
  },
  methods: {
    btclick (id) {
      this.btid = id
    },
    bt_enter () {
      if (this.btid == -1) {
        this.$message({
          message: "请选择一个身份!",
          type: "warning",
        })
      } else {
        this.dialogVisible = false
        setIdentifierToken(this.btid)
        this.$router.push({ path: "/home" })
        this.getuserinfo()
      }
    },
    is_inarr (num) {
      return this.identifiers.includes(num)
    },
    // 登录
    handleLogin () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store.dispatch("login", this.loginForm).then(() => {
            this.identifiers = getIdentifierToken()
            if (this.identifiers) {
              this.identifiers = this.identifiers.split(",")
              if (this.identifiers.length >= 1) {
                setIdentifierToken(this.identifiers[0])
                this.$store.commit("SET_Identifier", this.identifiers[0])
                setTimeout(() => {
                  this.getuserinfo()
                  this.$router.push({ path: "/home" })
                  localStorage.setItem('live_login_flag', "0")
                }, 1 * 1000)
              } else {
                this.dialogVisible = true
              }
            }
            if (this.checked == true) {
              //传入账号名，密码，和保存天数3个参数
              this.setCookie(
                this.loginForm.username,
                this.loginForm.password,
                7
              )
            } else {
              //清空Cookie
              this.clearCookie()
            }
            this.loading = false
          })
            .catch(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    //设置cookie
    setCookie (c_name, c_pwd, exdays) {
      var exdate = new Date() //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) //保存的天数
      //字符串拼接cookie
      window.document.cookie =
        "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString()
      window.document.cookie =
        "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString()
    },
    //读取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; ") //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("=") //再次切割
          //判断查找相对应的值
          if (arr2[0] == "userName") {
            this.loginForm.username = arr2[1] //保存到保存数据的地方
          } else if (arr2[0] == "userPwd") {
            this.loginForm.password = arr2[1]
            this.checked = true
          }
        }
      }
    },
    //清除cookie
    clearCookie: function () {
      this.setCookie("", "", -1) //修改2值都为空，天数为负1天就好了
    },
    getuserinfo (params) {
      getuserinfo(params)
        .then((response) => {
          if (response.code == 0) {
            this.$store.commit("SET_Username", response.data.name)
            setWebNameToken(response.data.name)
            this.$store.commit("SET_Useravater", response.data.avatar)
            setWebAvaterToken(response.data.avatar)
          }
        })
        .catch((error) => { })
    },
    //用户注册
    register () {
      this.$router.push({
        path: "/register",
      })
    },

    authentication () {
      if (process.env.NODE_ENV == 'development') {
        let str = request_base_path.dev_url + '#/sign_on'
        location.href = request_base_path.dev_url + 'cas-login?fromtype=3&redirect_url=' + encodeURIComponent(str)
      } else if (process.env.NODE_ENV == 'production') {
        let str = request_base_path.pro_url + '#/sign_on'
        location.href = request_base_path.pro_url + 'cas-login?fromtype=3&redirect_url=' + encodeURIComponent(str)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  height: 100%;
  width: 100%;
  position: relative;
  background: url('../../assets/登录/ujoybj.png') 0 0 no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .centercontain {
    height: auto;
    display: flex;
    width: 65%;

    // padding-bottom: 20px;
    //大于2400px，
    // @media screen and (min-width: 2400px) {
    //   height: auto;
    //   display: flex;
    //   width: 50%;
    // }
    // //大于1920
    // @media screen and (min-width: 1920px) {
    //   height: auto;
    //   display: flex;
    //   width: 60%;
    // }
    // //大于1680
    // @media screen and (max-width: 1680px) {
    //   height: auto;
    //   display: flex;
    //   width: 70%;
    // }
    // //1280分辨率以上
    // @media screen and (min-width: 1200px) {
    //   height: auto;
    //   display: flex;
    //   width: 70%;
    // }
    // //1100分辨率(大于960px，小于1199px)
    // @media screen and (min-width: 960px) and (max-width: 1199px) {
    //   height: auto;
    //   display: flex;
    //   width: 70%;
    // }
    // //880分辨率(大于768px，小于959px)
    // @media screen and (min-width: 768px) and (max-width: 959px) {
    //   height: auto;
    //   display: flex;
    //   width: 70%;
    // }
    // //720分辨率(大于480px，小于767px)
    // @media only screen and (min-width: 480px) and (max-width: 767px) {
    //   height: auto;
    //   display: flex;
    //   width: 70%;
    // }
    // //440分辨率以下(小于479px)
    // @media only screen and (max-width: 479px) {
    //   height: auto;
    //   display: flex;
    //   width: 70%;
    // }

    .left {
      position: relative;
      width: 481px;
      height: 100%;

      .logocintain {
        width: inherit;
        height: inherit;
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
        }
      }

      .backimage {
        margin-bottom: -4px;
      }
    }

    .right {
      flex: 1;
      background: #ffffff;

      .logoitem {
        margin-top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        .inputcontain {
          width: 411px;
          height: 60px;
          border: 1px solid #3d84ff;
          border-radius: 10px;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .user {
            margin-left: 20px;
          }

          .elinput {
            ::v-deep .el-input__inner {
              border: none;
            }
          }
        }
      }

      .logoitem2 {
        margin-top: 78px;
      }

      .elform {
        .elitem {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          .inputcontain {
            width: 411px;
            height: 60px;
            border: 1px solid #3d84ff;
            border-radius: 10px;
            padding: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .user {
              margin-left: 20px;

              width: 27px;
              height: 27px;
            }

            .elinput {
              ::v-deep .el-input__inner {
                border: none;
              }
            }
          }
        }

        .elitem2 {
          margin-top: 31px;
        }
      }

      .passtip {
        margin-top: 10px;

        .contain {
          width: 411px;
          height: 30px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding-left: 14px;
          padding-right: 14px;

          .el-checkbox__label {
            cursor: pointer;
          }
        }

        .contain_reg {
          cursor: pointer;
        }
      }

      .logbt {
        margin-top: 25px;

        .bt {
          width: 411px;
          height: 60px;
          color: white;
          background: #3d84ff;

          border-radius: 10px;
        }

        .loadingIcon {
          font-size: 20px;
        }

        .loadingText {
          transform: translateY(-2px);
          display: inline-block;
        }
      }
    }
  }

  .my_eldialog {
    .dialog_con {
      width: 100%;
      height: 340px;

      .title_t {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        color: #3d84ff;
      }

      .icon_con {
        margin: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        //  border: 1px solid red;
        .item_con {
          margin-right: 20px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .el_icon {
            position: relative;
            width: 80px;
            height: 80px;

            .img_icon {
              position: absolute;
              top: 0px;
            }
          }

          .text_t {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
          }
        }
      }

      .bt_con {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .bt {
          border-radius: 20px;
          width: 220px;
          height: 50px;
        }
      }
    }
  }
}

::v-deep .el-dialog {
  box-shadow: 1px 0px 6px 2px #ccc !important;
  border-radius: 10px !important;
}

::v-deep .el-dialog__header {
  padding: 0px;
}
</style>
